<template>
  <section class="section">
    <div class="container ist-container">
      <h1 class="title is-4">
        New Simulation
      </h1>
      <div style="margin: 4em 0 5em 0;">
        <StepIndicator
          v-model="activeStep"
          :labels="stepLabels"
          :warnings="stepWarnings"
        />
      </div>
      <!-- Setup -->
      <div v-if="activeStep === 0">
        <h2 class="title is-3">
          Setup
        </h2>
        <div class="columns">
          <div class="column is-6">
            <!-- Title -->
            <Field
              id="s0-title"
              v-model="title"
              label="Title"
              :validate="stepHighestVisited > 0"
            />
            <!-- Description -->
            <FieldText
              id="s0-description"
              v-model="description"
              label="Short description (optional)"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- Trial Design -->
      <div v-if="activeStep === 1">
        <h2 class="title is-3">
          Trial Design
        </h2>
        <div class="columns">
          <div class="column is-6">
            <!-- Administration type -->
            <FieldSelect
              id="s1-administration"
              v-model="administration"
              label="Administration type"
              help="Trial simulations can be performed for a cytotoxic drug administered either orally or intravenously. Select the 'Intravenous infusion and oral' option if the trial schedule combines intravenous and oral doses of the same drug."
              :options="administrationOptions"
            />
          </div>
        </div>
        <!-- Dosing schedules -->
        <FieldRadio
          id="s1-schedule"
          v-model="scheduleType"
          label="Dosing schedules"
          :options="scheduleTypeOptions"
        />
        <div v-if="scheduleType.value === 'manual'">
          <!-- Manual entry of schedule -->
          <template
            v-for="(schedule, index) in schedules"
            :key="index"
          >
            <div
              id="s1-manualdosing"
              class="columns"
            >
              <div class="column is-2">
                <Field
                  v-model="schedules[index].time"
                  label="Time (d)"
                  vpositive-or-zero
                  help="Time of dose (d). It is recommended to set the time origin, day 0, at the time of first dose."
                  :validate="stepHighestVisited > 1"
                />
              </div>
              <div class="column is-2">
                <Field
                  v-model="schedules[index].amt"
                  label="Amount (mg)"
                  vpositive
                  :validate="stepHighestVisited > 1"
                />
              </div>
              <div
                v-if="administration.value === 'intravenous' || administration.value === 'both'"
                class="column is-3"
              >
                <Field
                  v-model="schedules[index].tinf"
                  label="Infusion duration (min)"
                  :help="tinfHelp"
                  :optional="administration.value === 'both'"
                  vpositive-or-zero
                  :validate="stepHighestVisited > 1"
                />
              </div>
              <div
                v-if="index > 0"
                class="column is-2"
              >
                <button
                  class="button is-danger"
                  style="margin-top: 2em;"
                  @click="removeDose(index)"
                >
                  <span class="icon">
                    <font-awesome-icon
                      icon="times"
                    />
                  </span>
                </button>
              </div>
            </div>
          </template>
          <button
            id="addDose"
            class="button is-primary"
            style="margin-top: -0.5em;"
            @click="addDose"
          >
            Add dose
          </button>
        </div>
        <div v-else>
          <div class="columns">
            <div class="column is-6">
              <!-- CSV upload of schedules -->
              <UploadCsv
                v-model="schedulesUpload"
                :headers="schedulesUploadHeaders"
                ref-name="schedules"
                :example-file="scheduleExample"
                :positive-columns="['amt']"
                help="The columns of the CSV file are:
    • sch_id: schedule identifier. Values must be arranged in sequential order 1,2,3, ...
    • time: time of dose (d). It is recommended to set the time origin, day 0, at the time of first dose.
    • amt: amount of drug administered (mg)
    • tinf: infusion duration (min) if the dose is given intravenously. A value of 0 is interpreted as a bolus injection. An empty field is interpreted as oral administration."
                label="Upload file with dosing schedules"
              />
            </div>
          </div>
        </div>
        <div
          class="columns"
          style="margin-top: 1em;"
        >
          <div class="column is-6">
            <!-- Treatment cycle duration -->
            <Field
              id="s1-treatment-cycle-duration"
              v-model="treatmentCycleDuration"
              label="Treatment cycle duration (d)"
              help="Treatment cycle duration is expressed in days, intended as time elapsed from day 0."
              :validate="stepHighestVisited > 1"
              vpositive
            />
            <!-- Population -->
            <Field
              id="s1-population"
              v-model="population"
              label="Number of patients"
              help="Number of patients per schedule. For each schedule, simulations of drug concentrations and neutrophil profiles will be performed on the same population of simulated patients."
              :validate="stepHighestVisited > 1"
              :vmin="1"
              :vmax="10000"
            />
            <!-- Neutrophil data -->
            <UploadCsv
              id="ancUpload"
              v-model="ancUpload"
              :headers="ancUploadHeaders"
              ref-name="anc"
              example-file="anc.csv"
              :positive-columns="['amt']"
              help="Individual absolute neutrophil count data can be uploaded and superimposed for comparison to simulation results. Note that this visual diagnostic option is useful only if the simulated dosing schedules are equal to those applied in the clinical study.

The columns of the CSV file are:
• sch_id: unique schedule identifier. Values must be arranged in sequential order 1,2,3,... If a dosing schedule was uploaded, the schedule identifiers must match. Otherwise, the identifier is 1.
• id: unique subject identifier
• time: time of absolute neutrophil count measurements (d). It is recommended to set the time origin, day 0, at the time of first dose.
• anc: individual measurements of absolute neutrophil count (10⁹ cells/L)

If the example file is uploaded, simulations should be run with the following settings: Dosing schedules as defined in schedule_iv.csv, and a three compartments intravenous infusion model with V = 37.2 L, CL = 582 L/d, V1 = 24.8 L, Q1 = 1630 L/d, V2 = 45.9 L, Q2 = 558 L/d."
              label="Upload file with neutrophil data (optional)"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- Pharmacokinetic Settings -->
      <div v-if="activeStep === 2">
        <h2 class="title is-3">
          Pharmacokinetic Settings
        </h2>
        <div class="columns">
          <div class="column">
            <div
              v-if="administration.value !== 'intravenous'"
              style="margin-bottom: 2em;"
            >
              <h2
                class="title is-5"
                style="margin-top: 1em;"
              >
                Absorption
                <FieldHelp
                  text="• Ka (1/d): first-order absorption rate constant in 1/days
• Tlag (d): absorption lag time in days
• F: bioavailability. Unitless parameter ranging between 0 and 1
• IIV_Ka: first-order absorption rate constant inter-individual variability
• IIV_Tlag: absorption lag time inter-individual variability
• IIV_F: bioavailability inter-individual variability

The PK parameters Ka, Tlag, and F represent typical population values. The individual bioavailability parameter, F_i, is modelled using a logit normal distribution, logit(F_i) = logit(F) + eta_i.
All other individual PK parameters, P_i, are modelled using a log-normal distribution, P_i = P x exp(eta_i). eta_i denotes the individual random effect, assumed to follow a normal distribution with mean zero value and variance IIV_P.

Population parameter fields must be filled. In absence of variability in a PK parameter, the corresponding IIV field can be left empty."
                />
              </h2>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="s2-KA"
                    v-model="KA"
                    label="Absorption rate constant Ka&nbsp;(1/d)"
                    :validate="stepHighestVisited > 2"
                    vpositive
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="s2-IIV-KA"
                    v-model="IIV_KA"
                    label="IIV<sub>Ka</sub>"
                    placeholder="-"
                    optional
                    vpositive
                    :validate="stepHighestVisited > 2"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="s2-tlag"
                    v-model="TLAG"
                    label="Absorption lag time Tlag&nbsp;(d)"
                    :validate="stepHighestVisited > 2"
                    vpositive-or-zero
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="pharmacokinetic-KA1-IIV"
                    v-model="IIV_TLAG"
                    label="IIV<sub>Tlag</sub>"
                    placeholder="-"
                    optional
                    vpositive
                    :validate="stepHighestVisited > 2"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column is-9">
                  <Field
                    id="pharmacokinetic-F-pop"
                    v-model="F"
                    label="Bioavailability F&nbsp;(-)"
                    :validate="stepHighestVisited > 2"
                    :v-exclusive-range-flag="IIV_F.value !== ''"
                    :vmin="0"
                    :vmax="1"
                  />
                </div>
                <div class="column is-3 has-bottom-field">
                  <Field
                    id="s2-IIV-F"
                    v-model="IIV_F"
                    label="IIV<sub>F</sub>"
                    placeholder="-"
                    optional
                    vpositive
                    :validate="stepHighestVisited > 2"
                  />
                </div>
              </div>
            </div>
            <h2
              class="title is-5"
              style="margin-top: 1em;"
            >
              Disposition
            </h2>
            <!-- Number of PK compartments -->
            <FieldSelect
              id="s2-compartments"
              v-model="compartments"
              label="Number of compartments"
              :options="compartmentOptions"
            />
            <h4
              class="title is-6"
              style="margin-top: 2em;"
            >
              Central compartment
              <FieldHelp
                text="• V (L): central volume in liters
• CL (L/d): central clearance in liters/days
• IIV_V: central volume inter-individual variability
• IIV_CL: clearance inter-individual variability

The PK parameters V and CL represent typical population values. The individual PK parameters P_i are modelled using a log-normal distribution, P_i = P x exp(eta_i). eta_i denotes the individual random effect, assumed to follow a normal distribution with mean zero value and variance IIV_P.

Population parameter fields must be filled. In absence of variability in a PK parameter, the corresponding IIV field can be left empty."
              />
            </h4>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-V"
                  v-model="V"
                  label="Central volume V&nbsp;(L)"
                  :validate="stepHighestVisited > 2"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-V-IIV"
                  v-model="IIV_V"
                  label="IIV<sub>V</sub>"
                  placeholder="-"
                  optional
                  vpositive
                  :validate="stepHighestVisited > 2"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-CL"
                  v-model="CL"
                  label="Central clearance CL&nbsp;(L/d)"
                  :validate="stepHighestVisited > 2"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-CL-IIV"
                  v-model="IIV_CL"
                  label="IIV<sub>CL</sub>"
                  placeholder="-"
                  optional
                  vpositive
                  :validate="stepHighestVisited > 2"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <figure class="image">
              <img :src="pkScheme">
            </figure>
          </div>
        </div>
        <div
          v-if="compartments.value > 1"
          class="columns"
        >
          <div
            v-if="compartments.value >= 2"
            class="column is-6"
          >
            <h4
              class="title is-6"
              style="margin-top: 1em;"
            >
              Peripheral compartment 1
              <FieldHelp
                text="• V1 (L):  first peripheral compartment volume in liters
• Q1 (L/d): intercompartmental clearance between central and first peripheral compartment in liters/days
• IIV_V1:  inter-individual variability of the first peripheral compartment volume
• IIV_Q1: inter-individual variability of the intercompartmental clearance between central and first peripheral compartment

The PK parameters V1 and Q1 represent typical population values. The individual PK parameters P_i are modelled using a log-normal distribution, P_i = P x exp(eta_i). eta_i denotes the individual random effect, assumed to follow a normal distribution with mean zero value and variance IIV_P.

Population parameter fields must be filled. In absence of variability in a PK parameter, the corresponding IIV field can be left empty."
              />
            </h4>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-V1"
                  v-model="V1"
                  label="Peripheral volume V<sub>1</sub>&nbsp;(L)"
                  :validate="stepHighestVisited > 2"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-V1-IIV"
                  v-model="IIV_V1"
                  label="IIV<sub>V1</sub>"
                  placeholder="-"
                  optional
                  :validate="stepHighestVisited > 2"
                  vpositive
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-Q1"
                  v-model="Q1"
                  label="Intercompartmental clearance Q<sub>1</sub>&nbsp;(L/d)"
                  :validate="stepHighestVisited > 2"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-Q1-IIV"
                  v-model="IIV_Q1"
                  label="IIV<sub>Q1</sub>"
                  placeholder="-"
                  optional
                  :validate="stepHighestVisited > 2"
                  vpositive
                />
              </div>
            </div>
          </div>
          <div
            v-if="compartments.value == 3"
            class="column"
          >
            <h4
              class="title is-6"
              style="margin-top: 1em;"
            >
              Peripheral compartment 2
              <FieldHelp
                text="• V2 (L):  second peripheral compartment volume in liters
• Q2 (L/d): intercompartmental clearance between central and second peripheral compartment in liters/days
• IIV_V2:  inter-individual variability of the second peripheral compartment volume
• IIV_Q2: inter-individual variability of the intercompartmental clearance between central and second peripheral compartment

The PK parameters V2 and Q2 represent typical population values. The individual PK parameters P_i are modelled using a log-normal distribution, P_i = P x exp(eta_i). eta_i denotes the individual random effect, assumed to follow a normal distribution with mean zero value and variance IIV_P.

Population parameter fields must be filled. In absence of variability in a PK parameter, the corresponding IIV field can be left empty."
              />
            </h4>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-V2"
                  v-model="V2"
                  label="Peripheral volume V<sub>2</sub>&nbsp;(L)"
                  vpositive
                  :validate="stepHighestVisited > 2"
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-V2-IIV"
                  v-model="IIV_V2"
                  label="IIV<sub>V2</sub>"
                  placeholder="-"
                  optional
                  vpositive
                  :validate="stepHighestVisited > 2"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="pharmacokinetic-Q2"
                  v-model="Q2"
                  label="Intercompartmental clearance Q<sub>2</sub>&nbsp;(L/d)"
                  vpositive
                  :validate="stepHighestVisited > 2"
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="pharmacokinetic-Q2-IIV"
                  v-model="IIV_Q2"
                  label="IIV<sub>Q2</sub>"
                  placeholder="-"
                  optional
                  vpositive
                  :validate="stepHighestVisited > 2"
                />
              </div>
            </div>
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- Pharmacodynamic Settings -->
      <div v-if="activeStep === 3">
        <h2 class="title is-3">
          Pharmacodynamic Settings
        </h2>
        <div class="columns">
          <div class="column is-6">
            <h3 class="title is-5">
              Drug parameter
            </h3>
            <Drug
              id="DrugParameter"
              v-model="strIMP_Drug"
              :drug="arrIMP_Drug"
              :validate="stepHighestVisited > 3"
              @drugUpdate="handleIMP_DrugUpdate"
            />
            <h3
              class="title is-5"
              style="margin-top: 1em;"
            >
              System parameters
              <FieldHelp
                text="System related parameters are independent on the cytotoxic drug and should not be changed unless other estimated values are available.

• Circ0: absolute neutrophil counts at baseline;
• MTT: mean transit/maturation time;
• Kcycle: first-order rate constant governing cell cycle dynamics within the stem cell compartment;
• Fprol: fraction of proliferative cells that enters into the maturation chain;
• gamma: parameter that modulates the magnitude of the feedback mechanism.

Parameters without variability can be specified by leaving the corresponding inter-individual variability fields empty."
              />
            </h3>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="s3-circ0"
                  v-model="CIRC0"
                  label="Circ<sub>0</sub> (x 10<sup>9</sup>/L)"
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="s3-iiv-circ0"
                  v-model="IIV_CIRC0"
                  label="IIV<sub>Circ0</sub>"
                  placeholder="-"
                  optional
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="s3-MTT"
                  v-model="MTT"
                  label="MTT (d)"
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="s3-iiv-MMT"
                  v-model="IIV_MTT"
                  label="IIV<sub>MTT</sub>"
                  placeholder="-"
                  optional
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="s3-kcycle"
                  v-model="KCYCLE"
                  label="K<sub>cycle</sub> (1/d)"
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="s3-iiv-kcycle"
                  v-model="IIV_KCYCLE"
                  label="IIV<sub>Kcycle</sub>"
                  placeholder="-"
                  optional
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="s3-fprol"
                  v-model="FPROL"
                  label="F<sub>prol</sub>"
                  :validate="stepHighestVisited > 3"
                  :v-exclusive-range-flag="IIV_FPROL.value !== ''"
                  :vmin="0"
                  :vmax="1"
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="s3-iiv-fprol"
                  v-model="IIV_FPROL"
                  label="IIV<sub>Fprol</sub>"
                  placeholder="-"
                  optional
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
            </div>
            <div class="columns">
              <div class="column is-9">
                <Field
                  id="s3-gamma"
                  v-model="GAMMA"
                  label="γ (-)"
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
              <div class="column is-3 has-bottom-field">
                <Field
                  id="s3-iiv-gamma"
                  v-model="IIV_GAMMA"
                  label="IIV<sub>γ</sub>"
                  placeholder="-"
                  optional
                  :validate="stepHighestVisited > 3"
                  vpositive
                />
              </div>
            </div>
          </div>
          <div class="column">
            <figure
              class="image"
              style="max-width: 80%"
            >
              <img src="../assets/PDscheme.png">
            </figure>
            <p>
              Semi-mechanistic model of chemotherapy-induced neutropenia
              <FieldHelp
                text="The structural model consists of

• a stem cell compartment
• three transit compartments TR1, TR2, TR3
• a blood compartment with circulating cells.

Within the stem cell compartment, proliferative and quiescent cells coexist. Generation of new cells depends on the number of proliferative cells and a feedback mechanism from the circulating cells (Circ_0/Circ)^gamma. Only cells in the proliferative condition are sensitive to drug effects and capable of following the maturation chain.

The drug concentration Conc is assumed to reduce the proliferation rate or induce cell loss by the function E_drug = IMP_drug x Conc, where IMP_drug is a parameter quantifying the magnitude of the drug effect."
              />
            </p>
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- Run -->
      <div v-if="activeStep === 4">
        <h2 class="title is-3">
          Summary
        </h2>
        <SummaryItem
          label="Title"
          :item="title"
        />
        <SummaryItem
          v-if="description.value !== ''"
          label="Description"
          :item="description"
        />
        <h4
          class="title is-4"
          style="margin-top: 1em;"
        >
          Trial Design
        </h4>
        <SummaryItem
          label="Administration type"
          :item="adm_label"
        />
        <SummaryItem
          label="Treatment cycle duration (d)"
          :item="treatmentCycleDuration"
        />
        <SummaryItem
          label="Number of subjects per schedule"
          :item="population"
        />
        <table
          id="SummaryTrialDesign"
          class="table ist-table"
          style="margin-top: 1em;"
        >
          <thead>
            <tr>
              <th>Schedule ID</th>
              <th>Time of dose (d)</th>
              <th>Amount (mg)</th>
              <th>Infusion duration (min)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="sch in schedulesTableData"
              :key="sch.sch_id"
            >
              <td>{{ sch.sch_id }}</td>
              <td>{{ sch.time }}</td>
              <td>{{ sch.amt }}</td>
              <td>{{ sch.tinf === null || sch.tinf === '' ? 'n/a' : sch.tinf }}</td>
            </tr>
          </tbody>
        </table>
        <p
          v-if="manualScheduleError !== ''"
          class="has-text-warning"
          style="margin-bottom: 2em;"
        >
          {{ manualScheduleError }}
        </p>
        <div
          v-if="ancUpload !== null"
          style="margin-bottom: 2em;"
        >
          <p
            style="margin-bottom: 1em;"
          >
            Summary of ANC data
          </p>
          <Table
            v-if="ancUpload.status === 'ok'"
            :column-labels="ancUploadSummaryHeadersLabels"
            :columns="ancUploadSummaryHeaders"
            :rows="ancUploadSummary"
          />
          <p
            v-else
            class="has-text-warning"
          >
            Error with ANC upload.
          </p>
        </div>
        <h4 class="title is-4">
          Pharmacokinetic Settings
        </h4>
        <SummaryItem
          label="Number of compartments"
          :item="compartments"
        />
        <table
          id="SummaryPharmacokinetic"
          class="table ist-table"
          style="margin-top: 1em;"
        >
          <thead>
            <tr>
              <th class="left">
                Parameter
              </th>
              <th>Typical value</th>
              <th>IIV</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="administration.value !== 'intravenous'">
              <tr>
                <td class="left">
                  K<sub>a</sub> (1/d)
                </td>
                <td :class="{ 'is-warning': !KA.ok }">
                  {{ KA.value }}
                </td>
                <td
                  :class="{ 'is-warning': !IIV_KA.ok }"
                  v-html="IIV_KA.value === '' ? '-' : IIV_KA.value"
                />
              </tr>
              <tr>
                <td class="left">
                  Tlag (d)
                </td>
                <td :class="{ 'is-warning': !TLAG.ok }">
                  {{ TLAG.value }}
                </td>
                <td
                  :class="{ 'is-warning': !IIV_TLAG.ok }"
                  v-html="IIV_TLAG.value === '' ? '-' : IIV_TLAG.value"
                />
              </tr>
              <tr>
                <td class="left">
                  F
                </td>
                <td :class="{ 'is-warning': !F.ok }">
                  {{ F.value }}
                </td>
                <td
                  :class="{ 'is-warning': !IIV_F.ok }"
                  v-html="IIV_F.value === '' ? '-' : IIV_F.value"
                />
              </tr>
            </template>
            <tr>
              <td class="left">
                V (L)
              </td>
              <td :class="{ 'is-warning': !V.ok }">
                {{ V.value }}
              </td>
              <td
                :class="{ 'is-warning': !IIV_V.ok }"
                v-html="IIV_V.value === '' ? '-' : IIV_V.value"
              />
            </tr>
            <tr>
              <td class="left">
                CL (L/d)
              </td>
              <td :class="{ 'is-warning': !CL.ok }">
                {{ CL.value }}
              </td>
              <td
                :class="{ 'is-warning': !IIV_CL.ok }"
                v-html="IIV_CL.value === '' ? '-' : IIV_CL.value"
              />
            </tr>
            <template v-if="compartments.value > 1">
              <tr>
                <td class="left">
                  V1 (L)
                </td>
                <td :class="{ 'is-warning': !V1.ok }">
                  {{ V1.value }}
                </td>
                <td
                  :class="{ 'is-warning': !IIV_V1.ok }"
                  v-html="IIV_V1.value === '' ? '-' : IIV_V1.value"
                />
              </tr>
              <tr>
                <td class="left">
                  Q1 (L/d)
                </td>
                <td :class="{ 'is-warning': !Q1.ok }">
                  {{ Q1.value }}
                </td>
                <td
                  :class="{ 'is-warning': !IIV_Q1.ok }"
                  v-html="IIV_Q1.value === '' ? '-' : IIV_Q1.value"
                />
              </tr>
            </template>
            <template v-if="compartments.value > 2">
              <tr>
                <td class="left">
                  V2 (L)
                </td>
                <td :class="{ 'is-warning': !V2.ok }">
                  {{ V2.value }}
                </td>
                <td
                  :class="{ 'is-warning': !IIV_V2.ok }"
                  v-html="IIV_V2.value === '' ? '-' : IIV_V2.value"
                />
              </tr>
              <tr>
                <td class="left">
                  Q2 (L/d)
                </td>
                <td :class="{ 'is-warning': !Q2.ok }">
                  {{ Q2.value }}
                </td>
                <td
                  :class="{ 'is-warning': !IIV_Q2.ok }"
                  v-html="IIV_Q2.value === '' ? '-' : IIV_Q2.value"
                />
              </tr>
            </template>
          </tbody>
        </table>
        <h4 class="title is-4">
          Pharmacodynamic Settings
        </h4>
        <SummaryItem
          label="IMP<sub>drug</sub> (mL/ng)"
          :item="{ 'value': arrIMP_Drug.map((drug) => drug.value).join(', ')}"
        />
        <table
          id="SummaryPharmacodynamic"
          class="table ist-table"
          style="margin-top: 1em;"
        >
          <thead>
            <tr>
              <th class="left">
                Parameter
              </th>
              <th>Typical value</th>
              <th>IIV</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="left">
                Circ<sub>0</sub> (&#x00B7;10<sup>9</sup>/L)
              </td>
              <td :class="{ 'is-warning': !CIRC0.ok }">
                {{ CIRC0.value }}
              </td>
              <td
                :class="{ 'is-warning': !IIV_CIRC0.ok }"
                v-html="IIV_CIRC0.value === '' ? '-' : IIV_CIRC0.value"
              />
            </tr>
            <tr>
              <td class="left">
                MTT (d)
              </td>
              <td :class="{ 'is-warning': !MTT.ok }">
                {{ MTT.value }}
              </td>
              <td
                :class="{ 'is-warning': !IIV_MTT.ok }"
                v-html="IIV_MTT.value === '' ? '-' : IIV_MTT.value"
              />
            </tr>
            <tr>
              <td class="left">
                K<sub>cycle</sub> (1/d)
              </td>
              <td :class="{ 'is-warning': !KCYCLE.ok }">
                {{ KCYCLE.value }}
              </td>
              <td
                :class="{ 'is-warning': !IIV_KCYCLE.ok }"
                v-html="IIV_KCYCLE.value === '' ? '-' : IIV_KCYCLE.value"
              />
            </tr>
            <tr>
              <td class="left">
                F<sub>prol</sub>
              </td>
              <td :class="{ 'is-warning': !FPROL.ok }">
                {{ FPROL.value }}
              </td>
              <td
                :class="{ 'is-warning': !IIV_FPROL.ok }"
                v-html="IIV_FPROL.value === '' ? '-' : IIV_FPROL.value"
              />
            </tr>
            <tr>
              <td class="left">
                γ
              </td>
              <td :class="{ 'is-warning': !GAMMA.ok }">
                {{ GAMMA.value }}
              </td>
              <td
                :class="{ 'is-warning': !IIV_GAMMA.ok }"
                v-html="IIV_GAMMA.value === '' ? '-' : IIV_GAMMA.value"
              />
            </tr>
          </tbody>
        </table>
        <p
          style="margin-top: 2em"
          data-cy="tokens"
        >
          The simulation will consume {{ tokenCounter }}
          <span>token</span>
          <span v-if="tokenCounter > 1">s</span>.
          <FieldHelp text="One token is counted for every combination of schedule and drug parameter." />
          <span
            class="tag is-medium space-left"
            :class="{ 'is-warning': tokenCounter > tokensAvailable }"
          >{{ tokensAvailable }} tokens available</span>
        </p>
        <p v-if="tokenCounter > tokensAvailable">
          Not enough available tokens.
        </p>
        <p
          v-if="!jobValidates"
          class="margin-top: 1.5em"
        >
          Please provide missing input.
        </p>
        <button
          id="s4-submit"
          class="button more-space"
          :class="{'is-warning': !allowedToSubmit, 'is-success': allowedToSubmit }"
          :disabled="isSubmitting || !allowedToSubmit"
          @click="submitSimulation"
        >
          <Loader :is-loading="isSubmitting" />
          Run
        </button>
        <StepButtons
          v-model="activeStep"
          is-last
        />
      </div>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag';
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import FieldRadio from 'ist-skeleton-vue/src/components/Simulation/FieldRadio.vue';
import FieldSelect from 'ist-skeleton-vue/src/components/Simulation/FieldSelect.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import StepButtons from 'ist-skeleton-vue/src/components/Simulation/StepButtons.vue';
import StepIndicator from 'ist-skeleton-vue/src/components/Simulation/StepIndicator.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';
import Table from 'ist-skeleton-vue/src/components/Simulation/Table.vue';
import UploadCsv from 'ist-skeleton-vue/src/components/Simulation/UploadCsv.vue';
import Field from 'ist-skeleton-vue/src/components/Simulation/Field.vue';
import FieldText from 'ist-skeleton-vue/src/components/Simulation/FieldText.vue';
import Drug from '../components/Drug.vue';

function numberOrNull(model) {
  if (model.value === '' || model.value == null) {
    return null;
  }
  return Number(model.value);
}

export default {
  components: {
    Drug,
    Field,
    FieldHelp,
    FieldRadio,
    FieldSelect,
    Loader,
    FieldText,
    StepButtons,
    StepIndicator,
    SummaryItem,
    Table,
    UploadCsv,
  },
  data() {
    return {
      // wizard
      activeStep: 0,
      stepHighestVisited: 0,
      stepLabels: [
        'Setup',
        'Trial Design',
        'Pharmacokinetic Settings',
        'Pharmacodynamic Settings',
        'Run',
      ],
      // tokens
      tokensAvailable: 0,
      tokenCounter: 1,
      // form status
      isSubmitting: false,
      jobValidates: false,
      stepWarnings: [false, false, false, false, false],
      // trial design
      administrationOptions: [
        { value: 'intravenous', label: 'Intravenous infusion' },
        { value: 'oral', label: 'Oral' },
        { value: 'both', label: 'Intravenous infusion and oral' },
      ],
      scheduleTypeOptions: [
        { value: 'manual', label: 'Manual entry (one schedule)' },
        { value: 'upload', label: 'CSV upload (one or more schedules)' },
      ],
      schedulesUploadHeaders: ['sch_id', 'time', 'amt', 'tinf'],
      ancUploadHeaders: ['sch_id', 'id', 'time', 'anc'],
      ancUploadSummaryHeaders: ['schedule', 'patients', 'observations', 'time', 'anc'],
      ancUploadSummaryHeadersLabels: ['Schedule ID', '# Patients', '# Observations', 'Largest observation time (d)', 'ANC range (x10⁹ cells/L)'],
      // pharmacokinetic settings
      compartmentOptions: [
        { value: 1, label: 'One compartment' },
        { value: 2, label: 'Two compartments' },
        { value: 3, label: 'Three compartments' },
      ],
      //
      // USER DATA
      //
      // user data -- setup
      title: { value: '', ok: false },
      description: { value: '', ok: true },
      // user data -- trial design
      administration: { value: 'intravenous' },
      scheduleType: { value: 'manual' },
      schedules: [
        {
          sch_id: { value: '1', ok: true },
          time: { value: '0', ok: false },
          amt: { value: '', ok: false },
          tinf: { value: '', ok: false },
        },
      ],
      schedulesUpload: null,
      manualScheduleError: '',
      population: { value: '', ok: false },
      treatmentCycleDuration: { value: '', ok: false },
      ancUpload: null,
      ancUploadSummary: null,
      // user data -- pharmacokinetic settings
      compartments: { value: 1 },
      KA: { value: '', ok: false },
      IIV_KA: { value: '', ok: true },
      TLAG: { value: '0', ok: false },
      IIV_TLAG: { value: '', ok: true },
      F: { value: '1', ok: true },
      IIV_F: { value: '', ok: true },
      CL: { value: '', ok: false },
      IIV_CL: { value: '', ok: true },
      V: { value: '', ok: false },
      IIV_V: { value: '', ok: true },
      Q1: { value: '', ok: false },
      IIV_Q1: { value: '', ok: true },
      V1: { value: '', ok: false },
      IIV_V1: { value: '', ok: true },
      Q2: { value: '', ok: false },
      IIV_Q2: { value: '', ok: true },
      V2: { value: '', ok: false },
      IIV_V2: { value: '', ok: true },
      // user data -- pharmacodynamic settings
      strIMP_Drug: '',
      arrIMP_Drug: [],
      CIRC0: { value: '4.6', ok: true },
      IIV_CIRC0: { value: '0.103', ok: true },
      MTT: { value: '3.8', ok: true },
      IIV_MTT: { value: '0.0143', ok: true },
      KCYCLE: { value: '1.9', ok: true },
      IIV_KCYCLE: { value: '', ok: true },
      FPROL: { value: '0.58', ok: true },
      IIV_FPROL: { value: '0.0975', ok: true },
      GAMMA: { value: '0.37', ok: true },
      IIV_GAMMA: { value: '', ok: true },
    };
  },
  computed: {
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.jobValidates;
    },
    pkScheme() {
      let slideNumber = 0;
      if (this.administration.value === 'intravenous') {
        slideNumber = 1;
      } else if (this.administration.value === 'oral') {
        slideNumber = 4;
      } else {
        slideNumber = 7;
      }
      slideNumber += parseInt(this.compartments.value, 10) - 1;
      return require(`../assets/PK/Slide${slideNumber}.png`); // eslint-disable-line
    },
    adm_type() {
      if (this.administration.value === 'intravenous') return 1;
      if (this.administration.value === 'oral') return 2;
      return 3;
    },
    adm_label() {
      const administration = this.administrationOptions.find((adm) => adm.value === this.administration.value).label;
      return { value: administration, ok: true };
    },
    schedulesTableData() {
      if (this.scheduleType.value === 'upload') {
        return this.schedulesUpload != null ? this.schedulesUpload.data : [];
      }
      const data = this.schedules.map((sch) => ({
        sch_id: sch.sch_id.value,
        time: sch.time.value,
        amt: sch.amt.value,
        tinf: sch.tinf.value,
      }));
      return data;
    },
    scheduleExample() {
      if (this.administration.value === 'intravenous') return 'schedule_iv.csv';
      if (this.administration.value === 'oral') return 'schedule_oral.csv';
      return 'schedule_iv_oral.csv';
    },
    tinfHelp() {
      if (this.administration.value === 'intravenous') return 'Required field. Enter 0 for bolus injection.';
      if (this.administration.value === 'both') return 'Leave empty for oral administration, 0 for bolus injection, or enter infusion duration.';
      return '';
    },
  },
  watch: {
    activeStep(newStep) {
      if (newStep > this.stepHighestVisited) this.stepHighestVisited = newStep;
      if (newStep === this.stepLabels.length - 1) {
        this.countTokens();
        this.validateJob();
      }
    },
    administration(newAdministration) {
      this.schedulesUpload = null;
      if (newAdministration.value === 'intravenous') {
        this.KA = { value: '', ok: false };
        this.IIV_KA = { value: '', ok: false };
        this.TLAG = { value: '', ok: false };
        this.IIV_TLAG = { value: '', ok: false };
        this.F = { value: '', ok: false };
        this.IIV_F = { value: '', ok: false };
      }
    },
    ancUpload(newUpload) {
      if (newUpload !== null && newUpload.status === 'ok') {
        const summary = [];
        let schId = null;
        let observations = 0;
        let id = null;
        let elapsed = null;
        let ancLow = null;
        let ancHigh = null;
        newUpload.data.forEach((row) => {
          if (schId !== null && row.sch_id > schId) {
            summary.push({
              schedule: schId,
              patients: id,
              observations,
              time: elapsed,
              anc: `${ancLow.toFixed(3)} - ${ancHigh.toFixed(3)}`,
            });
            observations = 0;
            id = null;
            elapsed = null;
            ancLow = null;
            ancHigh = null;
          }
          if (schId === null || Number(row.sch_id) > schId) schId = Number(row.sch_id);
          observations += 1;
          if (id === null || Number(row.id) > id) id = Number(row.id);
          if (elapsed === null || Number(row.time) > elapsed) elapsed = Number(row.time);
          if (ancLow === null || Number(row.anc) < ancLow) ancLow = Number(row.anc);
          if (ancHigh === null || Number(row.anc) > ancHigh) ancHigh = Number(row.anc);
        });
        this.ancUploadSummary = summary;
      }
    },
    schedulesUpload(newUpload) {
      let error = null;
      if (newUpload !== null && newUpload.status === 'ok') {
        if (this.administration.value === 'oral') {
          if (newUpload.data.some((dose) => dose.tinf !== '')) error = 'Oral administration route selected but uploaded schedule contains doses with infusion duration `tinf`.';
        } else if (this.administration.value === 'intravenous') {
          if (newUpload.data.some((dose) => dose.tinf === '')) error = 'Intravenous administration route selected but uploaded schedule contains doses without infusion duration `tinf`. Reminder: Enter 0 for bolus injection.';
        } else { // both
          if (newUpload.data.every((dose) => dose.tinf === '')) error = 'Mixed administration route selected but no intravenous doses found.';
          if (newUpload.data.every((dose) => dose.tinf !== '')) error = 'Mixed administration route selected but no oral doses found.';
        }
      }
      if (error !== null) {
        this.schedulesUpload.status = 'error';
        this.$store.dispatch('modal/error', error);
      }
    },
    compartments(newCompartments) {
      if (newCompartments.value < 2) {
        this.V1 = { value: '', ok: false };
        this.IIV_V1 = { value: '', ok: false };
        this.Q1 = { value: '', ok: false };
        this.IIV_Q1 = { value: '', ok: false };
      }
      if (newCompartments.value < 3) {
        this.V2 = { value: '', ok: false };
        this.IIV_V2 = { value: '', ok: false };
        this.Q2 = { value: '', ok: false };
        this.IIV_Q2 = { value: '', ok: false };
      }
    },
  },
  mounted() {
    this.fetchTokens();
  },
  methods: {
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: this.$product.id,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
    countTokens() {
      const numberSchedules = new Set(this.schedulesTableData.map((dose) => dose.sch_id)).size;
      const numberSlopes = this.arrIMP_Drug.length;
      this.tokenCounter = numberSchedules * numberSlopes;
    },
    validateJob() {
      // setup
      const setupValidates = this.title.ok;
      // trial design
      let trialDesignValidates = this.population.ok && this.treatmentCycleDuration.ok;
      if (this.scheduleType.value === 'manual') {
        if (this.administration.value === 'oral') {
          if (this.schedules.length === 0 || this.schedules.some((dose) => !dose.time.ok || !dose.amt.ok)) {
            trialDesignValidates = false;
          }
        } else if (this.administration.value === 'intravenous') {
          if (this.schedules.length === 0 || this.schedules.some((dose) => !dose.time.ok || !dose.amt.ok || !dose.tinf.ok)) {
            trialDesignValidates = false;
          }
        } else { // both
          if (this.schedules.length === 0 || this.schedules.some((dose) => !dose.time.ok || !dose.amt.ok || !dose.tinf.ok)) {
            trialDesignValidates = false;
          }
          if (this.schedules.every((dose) => dose.tinf.value === '') || this.schedules.every((dose) => dose.tinf.value !== '')) {
            trialDesignValidates = false;
            this.manualScheduleError = 'Mixed administration route requires some fields with infusion duration and some without.';
          } else {
            this.manualScheduleError = '';
          }
        }
      } else if (this.schedulesUpload == null || this.schedulesUpload.status !== 'ok') {
        trialDesignValidates = false;
      }
      if (this.ancUpload !== null && this.ancUpload.status !== 'ok') {
        trialDesignValidates = false;
      }
      // pharmacokinetics
      let kineticsValidates = true;
      if (this.administration.value !== 'intravenous') {
        kineticsValidates = kineticsValidates && this.KA.ok && this.IIV_KA.ok && this.F.ok && this.IIV_F.ok;
      }
      kineticsValidates = kineticsValidates && this.V.ok && this.IIV_V.ok && this.CL.ok && this.IIV_CL.ok;
      if (this.compartments.value > 1) {
        kineticsValidates = kineticsValidates && this.V1.ok && this.IIV_V1.ok && this.Q1.ok && this.IIV_Q1.ok;
      }
      if (this.compartments.value > 2) {
        kineticsValidates = kineticsValidates && this.V2.ok && this.IIV_V2.ok && this.Q2.ok && this.IIV_Q2.ok;
      }
      // pharmacodynamics
      let dynamicsValidates = true;
      if (this.arrIMP_Drug.length === 0 || this.arrIMP_Drug.some((imp) => !imp.ok)) {
        dynamicsValidates = false;
      }
      dynamicsValidates = dynamicsValidates
        && this.CIRC0.ok && this.IIV_CIRC0.ok
        && this.KCYCLE.ok && this.IIV_KCYCLE.ok
        && this.FPROL.ok && this.IIV_FPROL.ok
        && this.GAMMA.ok && this.IIV_GAMMA.ok;
      // overall
      this.stepWarnings = [!setupValidates, !trialDesignValidates, !kineticsValidates, !dynamicsValidates, false];
      this.jobValidates = setupValidates && trialDesignValidates && kineticsValidates && dynamicsValidates;
    },
    handleIMP_DrugUpdate(arr) {
      this.arrIMP_Drug = arr;
    },
    addDose() {
      this.schedules.push({
        sch_id: { value: '1', ok: true },
        time: { value: '', ok: false },
        amt: { value: '', ok: false },
        tinf: { value: '', ok: false },
      });
    },
    removeDose(index) {
      this.schedules.splice(index, 1);
    },
    async submitSimulation() {
      this.isSubmitting = true;
      try {
        const inputData = {
          adm_type: this.adm_type,
          N_cmt: Number(this.compartments.value),
          ka_pop: numberOrNull(this.KA),
          tlag_pop: numberOrNull(this.TLAG),
          F_pop: numberOrNull(this.F),
          eta_ka: numberOrNull(this.IIV_KA),
          eta_tlag: numberOrNull(this.IIV_TLAG),
          eta_F: numberOrNull(this.IIV_F),
          V_pop: numberOrNull(this.V),
          CL_pop: numberOrNull(this.CL),
          eta_V: numberOrNull(this.IIV_V),
          eta_CL: numberOrNull(this.IIV_CL),
          V1_pop: numberOrNull(this.V1),
          Q1_pop: numberOrNull(this.Q1),
          eta_V1: numberOrNull(this.IIV_V1),
          eta_Q1: numberOrNull(this.IIV_Q1),
          V2_pop: numberOrNull(this.V2),
          Q2_pop: numberOrNull(this.Q2),
          eta_V2: numberOrNull(this.IIV_V2),
          eta_Q2: numberOrNull(this.IIV_Q2),
          SLP: this.arrIMP_Drug.map((imp) => Number(imp.value)),
          Circ0_pop: numberOrNull(this.CIRC0),
          eta_Circ0: numberOrNull(this.IIV_CIRC0),
          MTT_pop: numberOrNull(this.MTT),
          eta_MTT: numberOrNull(this.IIV_MTT),
          kcycle_pop: numberOrNull(this.KCYCLE),
          eta_kcycle: numberOrNull(this.IIV_KCYCLE),
          Fprol_pop: numberOrNull(this.FPROL),
          eta_Fprol: numberOrNull(this.IIV_FPROL),
          gama_pop: numberOrNull(this.GAMMA),
          eta_gama: numberOrNull(this.IIV_GAMMA),
          C_length: numberOrNull(this.treatmentCycleDuration),
          N_sbj: numberOrNull(this.population),
          anc: this.ancUpload === null ? null : this.ancUpload.data,
          scheduling: this.schedulesTableData,
          description: this.description.value,
        };

        if (window.Cypress) {
          // During E2E testing, input data is saved to global window object for retrieval.
          window.testSimulationInputs = inputData;
        }

        await this.$apollo.mutate({
          mutation: gql`
          mutation addDiflomotecanJobAndTasks($product_name: ProductName!, $tasks: [DiflomotecanTaskIn]!, $job_name: String!) {
            job: addDiflomotecanJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
              product_name
            }
          }`,
          variables: {
            product_name: this.$product.id,
            tasks: [inputData],
            job_name: this.title.value,
          },
        });
        this.$router.push('/home');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.more-space {
  margin-top: 1em;
}
.has-bottom-field {
  display: flex;
  align-items: flex-end;
}
.space-left {
  margin-left: 0.5em;
}
</style>
