const clientId = '1caad7c8-881f-4e20-90a5-b8bd1f36d393'; // b2c-msal2-ist-onco-diflomotecan
// authority: https://github.com/AzureAD/microsoft-authentication-library-for-dotnet/wiki/AAD-B2C-specifics
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1795
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1999
const b2cAuthority = 'istplatformb2c.b2clogin.com';
const publisherDomain = 'istplatformb2c.onmicrosoft.com';
const loginPolicy = 'B2C_1_signin_2';
const resetPasswordPolicy = 'B2C_1_resetpassword_2';

const config = {
  auth: {
    clientId,
    authority: `https://${b2cAuthority}/${publisherDomain}/${loginPolicy}`,
    knownAuthorities: [b2cAuthority],
    redirectUri: window.location.origin,
    navigateToRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          // eslint-disable-next-line no-useless-return
          return;
        }
        // console.log(message); // uncomment for MSAL debugging output
      },
      piiLoggingEnabled: false,
    },
  },
  scopes: [
    'openid', // needed for login request
    `https://${publisherDomain}/api-msal2/user_impersonation`,
  ],
  resetPasswordFlow: `https://${b2cAuthority}/${publisherDomain}/${resetPasswordPolicy}`,
};

export default config;
