<template>
  <div class="section">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-vertical">
          <div class="tile">
            <div class="tile is-4 is-vertical is-parent">
              <div class="tile is-child box">
                <NewSimulation />
              </div>
              <div class="tile is-child box">
                <Support />
              </div>
            </div>
            <div class="tile is-parent">
              <div class="tile is-child box">
                <LatestActivity />
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <HowToUse>
                  <p>
                    To simulate a clinical trial for the assessment of neutropenic effects caused by a chemotherapeutic agent, CTx NeutroSim requires specification of the trial design and information about the pharmacokinetic and pharmacodynamic properties of the compound.
                    Simulations can be performed for a compound given either by intravenous infusion or by oral administration, choosing among a 1, 2 or 3 pharmacokinetic compartment model structure.
                    The pharmacodynamic settings requires specification of the drug-related parameter value, which quantifies the drug impact on proliferative hematopoietic stem cells.
                  </p>
                  <p style="margin-top: 0.75em;">
                    Before running predictive simulations, it is recommended to upload neutrophil data and make use of visual diagnostic checks to select an adequate drug-related parameter value.
                    System-related parameter are set to the values estimated in
                    <span>(</span>
                    <span>Mangas-Sanjuan et al. 2015</span>
                    <span>)</span>
                    and their values can be modified if other estimates are available.
                  </p>
                  <p style="margin-top: 0.75em;">
                    Detailed information on how to use CTx NeutroSim is provided in the user manual below.
                  </p>
                  <ul style="margin-top: 0.75em;">
                    <li>
                      <a
                        :href="`${publicPath}CTx_NeutroSim_Manual.pdf`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >User Manual
                      </a>
                    </li>
                  </ul>
                </HowToUse>
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent">
              <div class="tile is-child box">
                <Documentation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HowToUse from 'ist-skeleton-vue/src/components/Home/HowToUse.vue';
import LatestActivity from 'ist-skeleton-vue/src/components/Home/LatestActivity.vue';
import NewSimulation from 'ist-skeleton-vue/src/components/Home/NewSimulation.vue';
import Support from 'ist-skeleton-vue/src/components/Home/Support.vue';
import Documentation from '../components/Documentation.vue';

export default {
  components: {
    Documentation,
    HowToUse,
    LatestActivity,
    NewSimulation,
    Support,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: mapState({
    userName: (state) => {
      if (state.iam.user) {
        return state.iam.user.name;
      }
      return 'user';
    },
  }),
};
</script>
