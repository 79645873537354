import image from '../assets/product.jpg';

const product = {
  id: 'diflomotecan',
  name: 'CTx NeutroSim',
  title: 'CTx NeutroSim',
  subtitle: 'Chemotherapy-induced Neutropenia Simulator',
  description: 'A tool that performs in silico clinical trials to assess the neutropenic effects of a chemotherapeutic agent in a virtual population of cancer patients.',
  collaboration: 'CTx NeutroSim is the result of the collaboration between the University of Navarra and InSilicoTrials Technologies.',
  image,
  features: [
    {
      icon: 'clipboard-check',
      title: 'Clinically validated',
      description: 'Validated on a clinical dataset of absolute neutrophil counts data from five phase I clinical studies including 111 cancer patients',
    },
    {
      icon: 'download',
      title: 'Display and Export',
      description: 'Results can easily be visualized and downloaded in CSV format and a simulation report is available in PDF',
    },
    {
      icon: 'thumbs-up',
      title: 'Easy to Use',
      description: 'Well-designed wizard that step-by-step guides through the setup and run of the simulation',
    },
  ],
};

export default product;
