<template>
  <div class="documentation">
    <h1 class="title">
      Documentation
    </h1>
    <p>
      Neutropenia prediction is based on a semimechanistic pharmacokinetic/pharmacodynamic model
      which describes neutropenic effects following the administration of a chemotherapeutic agent
      <span>(</span>
      <span>Mangas-Sanjuan et al. 2015</span>
      <span>)</span>.
    </p>
    <p style="margin-top: 0.75em;">
      This model could properly describe the neutropenic effects of diflomotecan after different dosing
      scenarios
      <span>(</span>
      <span>Mangas-Sanjuan et al. 2015</span>
      <span>)</span>
      and has been applied to paclitaxel data in a study by
      <span>(</span>
      <span>Henrich et al. 2017</span>
      <span>)</span>.
    </p>
    <p style="margin-top: 0.75em;">
      CTx NeutroSim enables simulations of clinical trials on a virtual population of cancer patients treated with a cytotoxic drug.
      It can be used to conduct in silico exploration of various dosing regimens and provide model-informed dosing recommendations for phase I/II clinical studies.
    </p>
    <div class="with-space">
      <h2 class="title is-4">
        Publications
      </h2>
      <ul>
        <li class="with-space">
          <span>Semimechanistic Bone Marrow Exhaustion Pharmacokinetic/Pharmacodynamic Model for Chemotherapy-Induced Cumulative Neutropenia
          </span>
          <br>Henrich, A., Joerger, M., Kraff, S., Jaehde, U., Huisinga, W., Kloft, C., Parra-Guillen, Z.P.,
          J. Pharmacol. Exp. Ther. 362, 347–358
          <b>2017</b>
          <br>DOI: 10.1124/jpet.117.240309
        </li>
        <li class="with-space">
          <span>
            Semimechanistic Cell-Cycle Type–Based Pharmacokinetic/Pharmacodynamic Model of Chemotherapy-Induced Neutropenic Effects of Diflomotecan under Different Dosing Schedules
          </span>
          <br>Mangas-Sanjuan, V., Buil-Bruna, N., Garrido, M.J., Soto, E., Trocóniz, I.F.,
          J. Pharmacol. Exp. Ther. 354, 55–64.
          <b>2015</b>
          <br>DOI: 10.1124/jpet.115.223776
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
div.documentation {
  padding-right: 2em;
  line-height: 1.7;
}
</style>
