<template>
  <div class="section">
    <div class="container ist-container">
      <template v-if="task == null">
        <h1 class="title">
          <Loader :is-loading="true" />
          Results
        </h1>
      </template>
      <template v-else>
        <div class="buttons is-pulled-right">
          <EditJobName
            :name="job.name"
            @jobNameEdit="handleJobNameEdit"
          />
          <ReportModal
            v-if="job.status == 'completed' && task.status === 'success'"
            :is-loading="isPdfLoading"
            @generateReport="downloadPdf"
          />
        </div>
        <h1
          class="title is-4 no-space-bottom"
          data-cy="job-title"
        >
          {{ job.name }}
        </h1>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p>started: {{ $filters.formatDate(task.started_at) }}</p>
            </div>
            <div class="level-item">
              <p>completed: {{ $filters.formatDate(task.finished_at) }}</p>
            </div>
            <div class="level-item">
              <span
                class="tag is-medium"
                :class="{ 'is-warning': task.status !== 'success' }"
                data-cy="task-status"
              >{{ task.status }}</span>
            </div>
          </div>
        </div>
        <InputsSummary>
          <SummaryItem
            v-if="input.description !== ''"
            id="SummaryDescription"
            label="Description"
            :value="input.description"
          />
          <div style="margin-top: 2em; margin-bottom: 1em;">
            <h4 class="title is-4">
              Trial Design
            </h4>
            <SummaryItem
              id="SummaryAdministrationType"
              label="Administration type"
              :value="adm_label"
            />
            <SummaryItem
              id="SummaryCycleDuration"
              label="Treatment cycle duration (d)"
              :value="String(input.C_length)"
            />
            <SummaryItem
              id="SummaryNumberSubjects"
              label="Number of subjects per schedule"
              :value="String(input.N_sbj)"
            />
            <div style="margin-top: 1em; margin-bottom: 2em;">
              <Table
                id="inputs-schedules-table"
                :column-labels="scheduleLabels"
                :columns="scheduleHeaders"
                :rows="schedule"
              />
            </div>
            <h4 class="title is-4">
              Pharmacokinetic Settings
            </h4>
            <SummaryItem
              id="SummaryNumberCompartments"
              label="Number of compartments"
              :value="String(input.N_cmt)"
            />
            <div style="margin-top: 1em; margin-bottom: 2em;">
              <table
                id="SummaryPharmacokineticTable"
                class="table ist-table"
              >
                <thead>
                  <tr>
                    <th class="left">
                      Parameter
                    </th>
                    <th>Typical value</th>
                    <th>IIV</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="input.adm_type !== 1">
                    <tr>
                      <td class="left">
                        K<sub>a</sub> (1/d)
                      </td>
                      <td>
                        {{ input.ka_pop }}
                      </td>
                      <td v-html="input.eta_ka === null ? '-' : input.eta_ka" />
                    </tr>
                    <tr>
                      <td class="left">
                        Tlag (d)
                      </td>
                      <td>
                        {{ input.tlag_pop }}
                      </td>
                      <td v-html="input.eta_tlag === null ? '-' : input.eta_tlag" />
                    </tr>
                    <tr>
                      <td class="left">
                        F
                      </td>
                      <td>
                        {{ input.F_pop }}
                      </td>
                      <td v-html="input.eta_F === null ? '-' : input.eta_F" />
                    </tr>
                  </template>
                  <tr>
                    <td class="left">
                      V (L)
                    </td>
                    <td>
                      {{ input.V_pop }}
                    </td>
                    <td v-html="input.eta_V === null ? '-' : input.eta_V" />
                  </tr>
                  <tr>
                    <td class="left">
                      CL (L/d)
                    </td>
                    <td>
                      {{ input.CL_pop }}
                    </td>
                    <td v-html="input.eta_CL === null ? '-' : input.eta_CL" />
                  </tr>
                  <template v-if="input.N_cmt > 1">
                    <tr>
                      <td class="left">
                        V1 (L)
                      </td>
                      <td>
                        {{ input.V1_pop }}
                      </td>
                      <td v-html="input.eta_V1 === null ? '-' : input.eta_V1" />
                    </tr>
                    <tr>
                      <td class="left">
                        Q1 (L/d)
                      </td>
                      <td>
                        {{ input.Q1_pop }}
                      </td>
                      <td v-html="input.eta_Q1 === null ? '-' : input.eta_Q1" />
                    </tr>
                  </template>
                  <template v-if="input.N_cmt > 2">
                    <tr>
                      <td class="left">
                        V2 (L)
                      </td>
                      <td>
                        {{ input.V2_pop }}
                      </td>
                      <td v-html="input.eta_V2 === null ? '-' : input.eta_V2" />
                    </tr>
                    <tr>
                      <td class="left">
                        Q2 (L/d)
                      </td>
                      <td>
                        {{ input.Q2_pop }}
                      </td>
                      <td v-html="input.eta_Q2 === null ? '-' : input.eta_Q2" />
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <h4 class="title is-4">
              Pharmacodynamic Settings
            </h4>
            <SummaryItem
              id="SummaryIMP"
              label="IMP<sub>drug</sub> (mL/ng)"
              :value="input.SLP.join(', ')"
            />
            <table
              id="SummaryPharmacodynamicTable"
              class="table ist-table"
              style="margin-top: 1.5em;"
            >
              <thead>
                <tr>
                  <th class="left">
                    Parameter
                  </th>
                  <th>Typical value</th>
                  <th>IIV</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="left">
                    Circ<sub>0</sub> (&#x00B7;10<sup>9</sup>/L)
                  </td>
                  <td>
                    {{ input.Circ0_pop }}
                  </td>
                  <td v-html="input.eta_Circ0 === null ? '-' : input.eta_Circ0" />
                </tr>
                <tr>
                  <td class="left">
                    MTT (d)
                  </td>
                  <td>
                    {{ input.MTT_pop }}
                  </td>
                  <td v-html="input.eta_MTT === null ? '-' : input.eta_MTT" />
                </tr>
                <tr>
                  <td class="left">
                    K<sub>cycle</sub> (1/d)
                  </td>
                  <td>
                    {{ input.kcycle_pop }}
                  </td>
                  <td v-html="input.eta_kcycle === null ? '-' : input.eta_kcycle" />
                </tr>
                <tr>
                  <td class="left">
                    F<sub>prol</sub>
                  </td>
                  <td>
                    {{ input.Fprol_pop }}
                  </td>
                  <td v-html="input.eta_Fprol === null ? '-' : input.eta_Fprol" />
                </tr>
                <tr>
                  <td class="left">
                    γ
                  </td>
                  <td>
                    {{ input.gama_pop }}
                  </td>
                  <td v-html="input.eta_gama === null ? '-' : input.eta_gama" />
                </tr>
              </tbody>
            </table>
          </div>
          <hr>
        </InputsSummary>
        <template v-if="job.status == 'completed' && resultsReady">
          <h3
            class="title is-6"
            style="margin-top: 2em;"
          >
            Drug parameter IMP<sub>drug</sub>
          </h3>
          <div
            id="NeutropeniaResults"
            class="tabs is-boxed tabs-with-space"
          >
            <ul>
              <li
                v-for="(SLP, index) in input.SLP"
                :key="index"
                :class="{ 'is-active': activeSLP === index }"
              >
                <a @click="activeSLP = index">
                  {{ SLP }} mL/ng
                </a>
              </li>
            </ul>
          </div>
          <h3
            class="title is-4"
            style=""
          >
            Neutropenia incidence
            <FieldHelp
              text="• ANC (10^9 cells/L) : absolute neutrophil count;
• NANC (10^9 cells/L): median nadir absolute neutrophil count value during treatment cycle;
• TTN (days): time to nadir median value;
• Grade 0 (%): percentage of individuals with nadir ANC larger than 2 x10^9 cells/L during treatment cycle;
• Grade 1 (%): percentage of individuals with nadir ANC between 1.5 and 2 x10^9 cells/L during treatment cycle;
• Grade 2 (%): percentage of individuals with nadir ANC between 1 and 1.5 x10^9 cells/L during treatment cycle;
• Grade 3 (%): percentage of individuals with nadir ANC between 0.5 and 1 x10^9 cells/L during treatment cycle;
• Grade 4 (%): percentage of individuals with nadir ANC lower than 0.5 x10^9 cells/L during treatment cycle;
• Recovery (%): percentage of individuals with ANC > 2 x10^9 cells/L at the end of treatment cycle"
            />
          </h3>
          <Table
            v-if="output != null"
            id="incidence-table"
            :column-labels="incidenceLabelsTable"
            :columns="incidenceLabelsTable"
            :rows="output[activeSLP]"
          />
          <h3
            class="title is-4"
            style="margin-top: 2em;"
          >
            Absolute neutrophil count population distribution
          </h3>
          <div
            v-if="scheduleLength > 1"
            class="tabs is-toggle is-centered tabs-with-space-above"
          >
            <ul>
              <li
                v-for="(_, index) in scheduleLength"
                :key="index"
                :class="{ 'is-active': activeSchedule === index }"
              >
                <a @click="activeSchedule = index">
                  Schedule {{ index + 1 }}
                </a>
              </li>
            </ul>
          </div>
          <figure class="image figure-with-space">
            <img
              v-if="activeSchedule != null"
              :src="resultsURL(`SLP${activeSLP+1}/schedule_${activeSchedule+1}.png`)"
            >
          </figure>
          <p
            v-if="output[activeSLP][activeSchedule].perc_data.constructor !== Object"
            style="margin-bottom: 2em;"
          >
            Percentage of observations within the 90&nbsp;% prediction interval: {{ output[activeSLP][activeSchedule].perc_data['0'] }}&nbsp;%.
          </p>
          <p class="buttons">
            <a
              class="button is-primary"
              style="margin-right: 0.25em;"
              :href="activeFigureURL"
              target="_blank"
              download
              data-cy="results-download"
            >
              <font-awesome-icon
                class="icon-with-margin"
                icon="download"
              />
              Results
            </a>
          </p>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import Papa from 'papaparse';
import EditJobName from 'ist-skeleton-vue/src/components/Results/EditJobName.vue';
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';
import InputsSummary from 'ist-skeleton-vue/src/components/Results/InputsSummary.vue';
import Loader from 'ist-skeleton-vue/src/components/Loader.vue';
import ReportModal from 'ist-skeleton-vue/src/components/Results/ReportModal.vue';
import Table from 'ist-skeleton-vue/src/components/Simulation/Table.vue';
import SummaryItem from 'ist-skeleton-vue/src/components/Simulation/SummaryItem.vue';

Papa.parsePromise = (file) => new Promise((complete, error) => {
  Papa.parse(file, {
    complete,
    error,
    skipEmptyLines: true,
    download: true,
    header: true,
  });
});

export default {
  components: {
    EditJobName,
    FieldHelp,
    InputsSummary,
    Loader,
    ReportModal,
    SummaryItem,
    Table,
  },
  data() {
    return {
      // job data
      job: null,
      task: null,
      input: null,
      schedule: null,
      output: null,
      // input summary
      scheduleLabels: [
        'Schedule ID',
        'Time of dose (d)',
        'Amount (mg)',
        'Infusion duration (min)',
      ],
      scheduleHeaders: [
        'sch_id',
        'time',
        'amt',
        'tinf',
      ],
      // media data
      baseUrl: null,
      containerSAS: null,
      // view data
      incidenceLabels: [],
      incidendeLabelsClean: [], // for LaTeX
      incidenceLabelsTable: [], // for incidence table
      activeSLP: 0,
      activeSchedule: 0,
      resultsReady: false,
      // PDF Report
      isPdfLoading: false,
    };
  },
  computed: {
    activeFigureURL() {
      return this.resultsURL(`SLP${this.activeSLP + 1}/res.csv`);
    },
    adm_label() {
      const administrationOptions = [
        'Intravenous infusion',
        'Oral',
        'Intravenous infusion and oral',
      ];
      return administrationOptions[this.input.adm_type - 1];
    },
    scheduleLength() {
      if (this.schedule !== null) return new Set(this.schedule.map((dose) => dose.sch_id)).size;
      return 0;
    },
  },
  async mounted() {
    try {
      await this.fetchJob();
      await this.fetchMediaInfo();
      await this.fetchSchedule();
      if (this.task.status === 'success') {
        await this.fetchOutputJson();
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async fetchJob() {
      const response = await this.$apollo.query({
        query: gql`
          query getJob($product_name: ProductName!, $job_id: ID!) {
            job: getJob(product_name: $product_name, job_id: $job_id) {
              _id
              name
              status
              created_at
              tasks {
                id: _id
                input
                status
                started_at
                finished_at
              }
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
        },
      });
      this.job = response.data.job;
      [this.task] = this.job.tasks;
      this.input = this.task.input;
    },
    async fetchMediaInfo() {
      const response = await this.$apollo.query({
        query: gql`
          query getAccessInfo($product_name: ProductName!, $job_id: ID!, $task_id: ID) {
            info: getAccessInfo(product_name: $product_name, job_id: $job_id, task_id: $task_id) {
              baseURL
              containerSAS
            }
          }
        `,
        variables: {
          product_name: this.$product.id,
          job_id: this.$route.params.id,
          task_id: this.task.id,
        },
      });
      this.baseUrl = response.data.info.baseURL;
      this.containerSAS = response.data.info.containerSAS;
      this.resultsUrl = `${this.baseUrl}results/`;
    },
    async fetchSchedule() {
      const scheduleUrl = `${this.baseUrl}schedule.csv?${this.containerSAS}`;
      const schedule = await Papa.parsePromise(scheduleUrl);
      this.schedule = schedule.data;
    },
    async fetchOutputJson() {
      const incidence = [];
      for (let index = 1; index <= this.input.SLP.length; index += 1) {
        const output = this.resultsURL(`SLP${index}/table_incidence.json`);
        // eslint-disable-next-line no-await-in-loop
        const response = await fetch(output);
        // eslint-disable-next-line no-await-in-loop
        const json = await response.json();
        incidence.push(json);
      }
      this.incidenceLabels = Object.keys(incidence[0][0]);
      this.incidenceLabelsClean = [...this.incidenceLabels];
      this.incidenceLabelsClean[1] = 'NANC ($10^9$ cells/L)';
      this.incidenceLabelsClean.splice(-1); // remove last element with perc_data
      this.incidenceLabelsTable = [...this.incidenceLabels];
      this.incidenceLabelsTable.splice(-1); // remove last element with perc_data
      this.output = incidence;
      this.resultsReady = true;
    },
    resultsURL(filename) {
      return `${this.baseUrl}results/${filename}?${this.containerSAS}`;
    },
    handleJobNameEdit(newJobName) {
      this.job.name = newJobName;
    },
    capitalise(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async downloadPdf(userContent) {
      this.isPdfLoading = true;
      // refresh token for images
      await this.fetchMediaInfo();
      // shortcut
      const { input } = this;
      // tables
      const schedulingTable = {
        header: this.scheduleLabels,
        rows: this.schedule.map((sch) => [sch.sch_id, sch.time, sch.amt, sch.tinf]),
        caption: 'Dosing schedules.',
      };
      const inputPKTable = {
        header: ['Parameter', 'Typical value', 'IIV'],
        rows: [],
        caption: 'Pharmacokinetic parameter values.',
      };
      if (input.adm_type !== 1) {
        inputPKTable.rows.push(['K~a~ (1/d)', input.ka_pop, input.eta_ka === null ? '-' : input.eta_ka]);
        inputPKTable.rows.push(['Tlag (d)', input.tlag_pop, input.eta_tlag === null ? '-' : input.eta_tlag]);
        inputPKTable.rows.push(['F', input.F_pop, input.eta_F === null ? '-' : input.eta_F]);
      }
      inputPKTable.rows.push(['V (L)', input.V_pop, input.eta_V === null ? '-' : input.eta_V]);
      inputPKTable.rows.push(['CL (L/d)', input.CL_pop, input.eta_CL === null ? '-' : input.eta_CL]);
      if (input.N_cmt > 1) {
        inputPKTable.rows.push(['V1 (L)', input.V1_pop, input.eta_V1 === null ? '-' : input.eta_V1]);
        inputPKTable.rows.push(['Q1 (L/d)', input.Q1_pop, input.eta_Q1 === null ? '-' : input.eta_Q1]);
      }
      if (input.N_cmt > 2) {
        inputPKTable.rows.push(['V2 (L)', input.V2_pop, input.eta_V2 === null ? '-' : input.eta_V2]);
        inputPKTable.rows.push(['Q2 (L/d)', input.Q2_pop, input.eta_Q2 === null ? '-' : input.eta_Q2]);
      }
      const inputPDTable = {
        header: ['Parameter', 'Typical value', 'IIV'],
        rows: [
          ['Circ~0~ ($\\times 10^9/L$)', input.Circ0_pop, input.eta_Circ0 === null ? '-' : input.eta_Circ0],
          ['MTT (d)', input.MTT_pop, input.eta_MTT === null ? '-' : input.eta_MTT],
          ['K~cycle~ (1/d)', input.kcycle_pop, input.eta_kcycle === null ? '-' : input.eta_kcycle],
          ['F~prol~ (-)', input.Fprol_pop, input.eta_Fprol === null ? '-' : input.eta_Fprol],
          ['$\\gamma$ (-)', input.gama_pop, input.eta_gama === null ? '-' : input.eta_gama],
        ],
        caption: 'Pharmacodynamic system-related parameter values.',
      };
      const results = [];
      // stack with sections for results
      input.SLP.forEach((slp, index) => {
        results.push({ type: 'string', data: `## Drug parameter IMP~drug~ ${slp} mL/ng` });
        results.push({ type: 'string', data: '### Neutropenia incidence' });
        results.push({
          key: `incidence-${index}`,
          type: 'table',
          data: {
            header: this.incidenceLabelsClean,
            rows: this.output[index].map((inc) => Object.values(inc)),
            caption: 'Incidence of neutropenia grades for the different schedules.',
          },
        });
        results.push({ type: 'string', data: '### Drug concentration and absolute neutrophil count distributions\n\n' });
        for (let jndex = 0; jndex < this.scheduleLength; jndex += 1) {
          const figureToken = {
            type: 'figure',
            data: {
              caption: `Drug and ANC population distribution for schedule ${jndex + 1}.`,
              url: this.resultsURL(`SLP${index + 1}/schedule_${jndex + 1}.png`),
              limitHeight: true,
            },
          };
          if (this.output[index][jndex].perc_data.constructor !== Object) {
            figureToken.data.caption += ` Percentage of observations within the 90% prediction interval: ${this.output[index][jndex].perc_data['0']}%.`;
          }
          results.push(figureToken);
        }
      });
      // user content
      const userContentTokens = [];
      Object.keys(userContent).forEach((key) => {
        userContentTokens.push(
          { key, type: 'string', data: userContent[key] },
        );
      });
      // request PDF
      const response = await this.$api.call('POST', 'api/pdf-export-func', {
        header: {
          Accept: 'application/json',
        },
        responseType: 'blob',
        data: {
          template: 'diflomotecan',
          tokens: [
            ...userContentTokens,
            { key: 'title', type: 'string', data: this.job.name },
            { key: 'date', type: 'string', data: this.$filters.formatDate(this.task.finished_at) },
            { key: 'adm_label', type: 'string', data: this.adm_label },
            { key: 'C_length', type: 'string', data: input.C_length },
            { key: 'N_sbj', type: 'string', data: input.N_sbj },
            { key: 'schedulingTable', type: 'table', data: schedulingTable },
            { key: 'N_cmt', type: 'string', data: input.N_cmt },
            { key: 'inputPKTable', type: 'table', data: inputPKTable },
            { key: 'SLP', type: 'string', data: input.SLP.join(', ') },
            { key: 'inputPDTable', type: 'table', data: inputPDTable },
            { key: 'resultsStack', type: 'stack', data: results },
          ],
        },
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'CTx_NeutroSim_Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isPdfLoading = false;
    },
  },
};
</script>

<style scoped>
.no-space-bottom {
  margin-bottom: 0.1em !important;
}
.icon-with-margin {
  margin-right: 0.5em;
}
.tabs-with-space {
  margin-top: -0.5em;
  margin-bottom: 2em;
}
.tabs-with-space-above {
  margin-top: 2em;
  margin-bottom: 2em;
}
.figure-with-space {
  max-width: 50%;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}
</style>
