<template>
  <div>
    <div class="field">
      <label class="label has-text-weight-normal">
        <span>Enter one or more IMP<sub>drug</sub> values separated by semicolons (mL/ng)</span>
        <FieldHelp
          text="Parameter that quantifies the drug impact on the proliferative cells in the stem cell compartment. Drug impact is modeled as a linear function, E_drug = IMP_drug x Conc, of the drug concentration Conc.

IMP_drug value must be between 0 and 100. For the cytotoxic drug diflomotecan, the IMP_drug estimated value is 9.5 (95% CI, 8.2–10.2), (Mangas-Sanjuan et al. 2015).

If neutrophil data was uploaded, more than one IMP_drug value should be tested before running predictive simulations. For each IMP_drug value, a visual diagnostic check will be then produced to assess the agreement between observed and simulated neutrophil profiles."
        />
      </label>
      <p class="control">
        <input
          id="s3-imp-drug"
          class="input"
          type="text"
          :value="modelValue"
          :class="{ 'is-warning': hasWarning }"
          @input="onInput"
        >
      </p>
      <p class="help">
        <span
          v-if="hasWarning"
          class="has-text-warning"
        >
          Values must between 0 and 100.
        </span>
      </p>
    </div>
    <p>IMP<sub>drug</sub> values check:</p>
    <ul
      v-if="drug.length>0"
      class="with-space"
    >
      <li
        v-for="(v, index) in drug"
        :key="index"
        :class="{ 'has-text-warning': !v.ok }"
      >
        {{ v.value }}
        <span
          v-if="!v.ok"
          class="has-text-warning"
        >
          <font-awesome-icon icon="exclamation-triangle" />
        </span>
      </li>
    </ul>
    <p v-if="modelValue.length === 0">
      <span class="is-italic">None</span>
    </p>
  </div>
</template>

<script>
import FieldHelp from 'ist-skeleton-vue/src/components/Simulation/FieldHelp.vue';

function allGood(arr) {
  return arr.length > 0 && arr.every((obj) => obj.ok);
}

export default {
  components: {
    FieldHelp,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    drug: {
      type: Array,
      default() {
        return [];
      },
    },
    help: {
      type: String,
      default: '',
    },
    validate: Boolean,
  },
  emits: ['update:modelValue', 'drugUpdate'],
  computed: {
    hasWarning() {
      if (!this.validate && this.modelValue === '') {
        return false;
      }
      return !allGood(this.drug);
    },
  },
  methods: {
    allGood,
    onInput(event) {
      const { value } = event.target;
      const drug = [];
      value.split(';').forEach((v) => {
        if (v === '') return;
        const ok = v !== '' && !Number.isNaN(Number(v)) && v > 0 && v <= 100;
        drug.push({
          value: v, ok,
        });
      });
      this.$emit('update:modelValue', value);
      this.$emit('drugUpdate', drug);
    },
  },
};
</script>

<style scoped>
ul.with-space {
  margin-top: 0.5em;
}
.help-icon {
  cursor: help;
  margin-left: 0.25em;
}
</style>
